import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Container, Text, Image } from "@atoms";

const ImageColumns = ({ color, columns }) => {
  const container = {
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: "50%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      y: "0%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <Container>
      <m.ul
        variants={container}
        initial="hidden"
        whileInView="show"
        className="flex flex-wrap justify-center gap-10"
      >
        {columns?.map((col, i) => {
          const { uid, image, heading, subheading, descriptor } = col;
          return (
            <li
              key={uid}
              className={classNames(
                "flex flex-grow flex-col items-center gap-5 text-center sm:flex-[0_1_calc(50%-1.25rem)] md:flex-[0_1_calc(33.33%-1.67rem)]",
                {
                  "text-white":
                    color === "bg-teal-dark" || color === "bg-black",
                }
              )}
            >
              {!!image && (
                <m.div
                  variants={item}
                  className={classNames(
                    "relative flex h-32 w-32 items-center justify-center sm:h-36 sm:w-36"
                  )}
                >
                  <div className="relative z-10 w-[90%]">
                    <Image
                      image={image}
                      aspectRatio={[1, 1]}
                      className="rounded-full"
                    />
                  </div>
                  <div
                    className={classNames("absolute inset-0 rounded-full", {
                      "bg-teal": i % 3 === 0,
                      "bg-purple": i % 3 === 2,
                      "bg-pink": i % 3 === 1,
                    })}
                  />
                </m.div>
              )}
              <Text variant="h6" className="whitespace-pre-line">
                {heading}
              </Text>
              <Text variant="xs">{subheading}</Text>
              <Text variant="sm">{descriptor}</Text>
            </li>
          );
        })}
      </m.ul>
    </Container>
  );
};

ImageColumns.defaultProps = {};

export default ImageColumns;
